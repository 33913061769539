import { gql } from "graphql-request";
import { CollectionDTO, ID } from "../../Types";

export const GET_PARTS_QUERY = gql`
  query GetPartsQuery($partIds: [ID!]) {
    parts(includePartIds: $partIds, pageSize: 50) {
      collection {
        name
        id
        partNumber
      }
      errors {
        title
        message
      }
    }
  }
`;

export type GetPartsDTO = {
  name: string;
  id: string;
  partNumber: string;
};

export type GetPartsCollectionDTO = CollectionDTO<GetPartsDTO, "parts">;

export type GetPartsQueryParams = {
  partIds: ID[];
};

export const GET_PARTS_FOR_PROJECT_QUERY = gql`
  query GetPartsForProjectQuery($projectId: ID!, $pageSize: Int, $page: Int) {
    projectItems(
      page: $page
      pageSize: $pageSize
      projectId: $projectId
      itemType: Part
    ) {
      collection {
        createdAt
        id
        instanceParameters
        item
        quantity
        type
        updatedAt
        createChange {
          id
          event
          itemId
          itemType
          objectUser {
            id
            email
            displayName
            avatarUrl
          }
          objectUserId
          user {
            id
            email
            displayName
            avatarUrl
          }
        }
      }
    }
  }
`;

export type GetPartsItemCreateChangeDTO = {
  id: ID;
  event: string;
  itemId: ID;
  itemType: string;
  objectUser: {
    id: ID;
    email: string;
    displayName: string;
    avatarUrl: string;
  };
  objectUserId: ID;
  user: {
    id: ID;
    email: string;
    displayName: string;
    avatarUrl: string;
  };
};

export type GetPartsItemForProjectDTO = {
  id: ID;
  name: string;
  category: string;
  sub_category1: string;
  sub_category2: string;
  part_number: string;
  status: string;
  description: string;
  updated_at: string;
  weight: number | string;
  width: number | string;
  height: number | string;
  length: number | string;
  regional_unit_prices: {
    US: number;
  };
  computed_regional_unit_prices: {};
  unit_price: number | string;
};

export type GetPartsForProjectDTO = {
  createdAt: string;
  id: ID;
  instanceParameters: {};
  item: GetPartsItemForProjectDTO;
  quantity: number;
  type: string;
  updatedAt: string;
  createChange: GetPartsItemCreateChangeDTO;
};

export type GetPartsForProjectCollectionDTO = CollectionDTO<
  GetPartsForProjectDTO,
  "projectItems"
>;

export type GetPartsForProjectQueryParams = {
  projectId: ID;
  pageSize: number;
  page: number;
};

export const GET_PART_QUERY = gql`
  query GetPartQuery($partId: ID!) {
    part(id: $partId) {
      item {
        id
        name
        description
        fullUrl
        imagePath
      }
    }
  }
`;

export type GetPartItemDTO = {
  id: ID;
  name: string;
  description: string;
  fullUrl: string;
  imagePath: string;
};

export type GetPartDTO = {
  part: {
    item: GetPartItemDTO;
  };
};

export type GetPartQueryParams = {
  partId: ID;
};

export const GET_PARTS_BY_ID_OR_BY_PART_NUMBER_QUERY = gql`
  query GetPartByIdOrBYPartNumberQuery($partIdOrPartNumber: String) {
    parts(partNumber: $partIdOrPartNumber) {
      collection {
        id
        name
        description
        fullUrl
        imagePath
      }
    }
  }
`;

export type PartsByIdOrByPartNumberDTO = {
  id: ID;
  name: string;
  description: string;
  fullUrl: string;
  imagePath: string;
};

export type PartsByIdOrByPartNumberCollectionDTO = CollectionDTO<
  PartsByIdOrByPartNumberDTO,
  "parts"
>;

export type GetPartsByIdOrByPartNumberParams = {
  partIdOrPartNumber: string;
};
